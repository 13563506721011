export default {
  state: () => ({
    username: "",
    isManager: false,
    permissions: [],
  }),
  mutations: {
    setUser(state, item) {
      state.username = item.username;
      state.isManager = item.is_manager;
      state.permissions = item.permissions;
    },
  },
};
