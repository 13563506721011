import VueRouter from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: () => import("@/layouts/BaseLayout"),
    children: [
      { path: "home", meta: { title: "首页" }, component: () => import("@/views/home") },
      { path: "production_kanban", meta: { title: "生产看板" }, component: () => import("@/views/productionKanban") },
    ],
  },
  {
    path: "/data",
    component: () => import("@/layouts/BaseLayout"),
    children: [
      { path: "crossing", meta: { title: "道口管理" }, component: () => import("@/views/data/crossing") },
      { path: "con", meta: { title: "Con" }, component: () => import("@/views/data/con") },
      { path: "department", meta: { title: "Department" }, component: () => import("@/views/data/department") },
      { path: "airport", meta: { title: "Airport" }, component: () => import("@/views/data/airport") },
      {
        path: "destination_location",
        meta: { title: "Destination Location" },
        component: () => import("@/views/data/destinationLocation"),
      },
      { path: "country", meta: { title: "Contry" }, component: () => import("@/views/data/country") },
      { path: "residential", meta: { title: "Residential" }, component: () => import("@/views/data/residential") },
      { path: "postal_code", meta: { title: "Postal Code" }, component: () => import("@/views/data/postalCode") },
      { path: "sensitive", meta: { title: "敏感品" }, component: () => import("@/views/data/sensitive") },
      { path: "packing_code", meta: { title: "Packing Code" }, component: () => import("@/views/data/packingCode") },
    ],
  },
  {
    path: "/sorting",
    component: () => import("@/layouts/BaseLayout"),
    children: [
      { path: "sorting_rule", meta: { title: "分拣规则" }, component: () => import("@/views/sorting/sortingRule") },
      {
        path: "package_interception",
        meta: { title: "包裹拦截" },
        component: () => import("@/views/sorting/packageInterception"),
      },
      {
        path: "overlimit_interception",
        meta: { title: "超限拦截" },
        component: () => import("@/views/sorting/overlimitInterception"),
      },
      {
        path: "sensitive_interception",
        meta: { title: "敏感品拦截" },
        component: () => import("@/views/sorting/sensitiveInterception"),
      },
      { path: "package", meta: { title: "分拣计划" }, component: () => import("@/views/sorting/package") },
      { path: "sorting_record", meta: { title: "分拣记录" }, component: () => import("@/views/sorting/sortingRecord") },
      { path: "sorting_report", meta: { title: "分拣报告" }, component: () => import("@/views/sorting/sortingReport") },
      {
        path: "sorting_report_detail",
        meta: { title: "分拣报告详情" },
        component: () => import("@/views/sorting/sortingReportDetail"),
      },
      {
        path: "startime_upload_record",
        meta: { title: "米仓上传记录" },
        component: () => import("@/views/sorting/startimeUploadRecord"),
      },
      {
        path: "shein_upload_record",
        meta: { title: "希音上传记录" },
        component: () => import("@/views/sorting/sheinUploadRecord"),
      },
      {
        path: "bind_con_record",
        meta: { title: "集 Con 记录" },
        component: () => import("@/views/sorting/bindConRecord"),
      },
    ],
  },
  {
    path: "/calibration",
    component: () => import("@/layouts/BaseLayout"),
    children: [
      {
        path: "standard_package",
        meta: { title: "标准件" },
        component: () => import("@/views/calibration/standardPackage"),
      },
      { path: "measurement_task", meta: { title: "测量任务" }, component: () => import("@/views/calibration/measurementTask") },
    ],
  },
  {
    path: "/log",
    component: () => import("@/layouts/BaseLayout"),
    children: [
      { path: "dws_upload_log_a", meta: { title: "DWS 上传日志 A" }, component: () => import("@/views/log/dwsUploadLogA") },
      { path: "dws_upload_log_b", meta: { title: "DWS 上传日志 B" }, component: () => import("@/views/log/dwsUploadLogB") },
      { path: "dws_upload_log_c", meta: { title: "DWS 上传日志 C" }, component: () => import("@/views/log/dwsUploadLogC") },
      { path: "plc_listen_log_a", meta: { title: "PLC 监听日志 A" }, component: () => import("@/views/log/plcListenLogA") },
      { path: "plc_listen_log_b", meta: { title: "PLC 监听日志 B" }, component: () => import("@/views/log/plcListenLogB") },
      { path: "fedex_upload_log", meta: { title: "Fedex 上传日志" }, component: () => import("@/views/log/fedexUploadLog") },
      { path: "shein_import_log", meta: { title: "Shein 导入日志" }, component: () => import("@/views/log/sheinImportLog") },
      { path: "shein_upload_log", meta: { title: "Shein 回传日志" }, component: () => import("@/views/log/sheinUploadLog") },
      {
        path: "startime_upload_log",
        meta: { title: "Startime 回传日志" },
        component: () => import("@/views/log/startimeUploadLog"),
      },
    ],
  },
  {
    path: "/system",
    component: () => import("@/layouts/BaseLayout"),
    children: [
      { path: "role", meta: { title: "角色管理" }, component: () => import("@/views/system/role") },
      { path: "account", meta: { title: "员工账号" }, component: () => import("@/views/system/account") },
      { path: "config", meta: { title: "系统配置" }, component: () => import("@/views/system/config") },
      {
        path: "mobile_download",
        meta: { title: "移动端下载" },
        component: () => import("@/views/system/mobileDownload"),
      },
    ],
  },
  {
    path: "/user",
    component: () => import("@/layouts/UserLayout"),
    children: [
      { path: "login", meta: { title: "首页" }, component: () => import("@/views/system/login") },
      { path: "set_password", meta: { title: "首页" }, component: () => import("@/views/system/setPassword") },
    ],
  },
  {
    path: "/kanban",
    component: () => import("@/layouts/KanbanLayout"),
    children: [{ path: "production", meta: { title: "生产看板" }, component: () => import("@/views/productionKanban") }],
  },
];

export default new VueRouter({ mode: "history", routes });
