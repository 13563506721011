import functions from "@/utils/functions";
import "ant-design-vue/dist/antd.less";
import Antd from "ant-design-vue/es";
import VueRouter from "vue-router";
import router from "./router";
import Storage from "vue-ls";
import store from "./store";
import App from "./App.vue";
import Vue from "vue";
import preview from "vue-photo-preview";
import "vue-photo-preview/dist/skin.css";
import VueFullscreen from "vue-fullscreen";

Vue.use(preview, { bgOpacity: 0.6, arrowKeys: false, wheelToZoom: true });

const storageOptions = {
  namespace: "威特豪斯-WCS",
  name: "ls",
  storage: "local",
};

Vue.config.productionTip = false;
Vue.use(Storage, storageOptions);
Vue.prototype.$functions = functions;
Vue.use(VueRouter);
Vue.use(Antd);
Vue.use(VueFullscreen);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
